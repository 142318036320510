<template>
	<v-sheet class="member" style="height: calc(100vh - 88px)" v-if="getPermission('member::view')">
		<template v-if="contentLoaded">
			<v-layout class="align-center light-blue-bg px-4 py-2">
				<v-flex md12>
					<div class="d-flex">
						<p class="my-auto font-level-3-bold">
							<ShowValue
								:object="member"
								object-key="display_name"
								label="incharge officer name"
							></ShowValue>
							<v-chip v-if="member.is_resigned" class="ma-2" color="orange" label text-color="white">
								Resigned
							</v-chip>
							<v-chip color="cyan" class="ml-2 mr-2" label text-color="white">{{ member.barcode }}</v-chip>
						</p>
						<v-spacer></v-spacer>
						<template v-if="getPermission('member::edit')">
							<v-btn
								v-if="!member.is_resigned"
								v-on:click="doAction('edit')"
								color="blue darken-4 text-white"
								class="ml-2"
								depressed
								tile
								><v-icon small left>mdi-pencil</v-icon>Edit</v-btn
							>
						</template>
						<v-btn
							v-if="false && !member.is_resigned && !member.is_owner"
							v-on:click="doAction('resign')"
							color="blue darken-4 text-white"
							class="ml-2"
							depressed
							tile
							><v-icon small left>mdi-file</v-icon>Resign</v-btn
						>
						<template v-if="!member.is_owner">
							<template v-if="getPermission('member::edit')">
								<v-btn
									v-if="member.activated"
									v-on:click="doAction('deactivate')"
									color="blue darken-4 text-white"
									class="ml-2"
									depressed
									tile
									><v-icon small left>mdi-cancel</v-icon>De-activate</v-btn
								>
								<v-btn
									v-else
									v-on:click="doAction('activate')"
									color="blue darken-4 text-white"
									class="ml-2"
									depressed
									tile
									><v-icon small left>mdi-check-all</v-icon>Activate</v-btn
								>
							</template>
						</template>
						<template v-if="getPermission('member::delete')">
							<v-btn
								v-if="!member.is_owner"
								v-on:click="doAction('delete')"
								color="red lighten-1 text-white"
								class="ml-2"
								depressed
								tile
								><v-icon small left>mdi-delete</v-icon>Delete</v-btn
							>
						</template>
						<template>
							<v-btn
								v-if="memberTab == 'address-book' && !member.is_resigned"
								v-on:click="doAction('new-address')"
								color="blue darken-4 text-white"
								class="ml-2"
								depressed
								tile
								><v-icon small left>mdi-playlist-plus</v-icon>Add New Address</v-btn
							>
						</template>
					</div>
					<div class="py-3">
						<table width="100%" style="table-layout: fixed">
							<tr>
								<td rowspan="5" width="15%" align="center">
									<ImageTemplate width="100px" circle :src="member.image"></ImageTemplate>
								</td>
								<td width="28.33%">
									<div class="mt-2">
										<span class="fw-600">Name : </span>
										<span class="fw-500">
											<ShowValue :object="member" object-key="display_name" label="name"></ShowValue>
										</span>
									</div>
								</td>
								<td width="28.33%">
									<div class="mt-2">
										<span class="fw-600 mr-2">Category : </span>
										<span class="fw-500">
											<span class="fw-500">
												<ShowValue
													:object="member"
													object-key="category_formatted"
													label="category"
												></ShowValue>
											</span>
										</span>
									</div>
								</td>
								<td width="28.33%">
									<div class="mt-2">
										<span class="fw-600">SIC Start Date : </span>
										<span class="fw-500">
											<ShowValue
												:object="member"
												object-key="sic_start_date_formatted"
												label="sic start date"
											></ShowValue>
										</span>
									</div>
								</td>
							</tr>
							<tr>
								<td width="28.33%">
									<div class="mt-2">
										<span class="fw-600">Email : </span>
										<span class="fw-500">
											<ShowValue :object="member" object-key="email" label="email id"></ShowValue>
										</span>
									</div>
								</td>
								<td width="28.33%">
									<div class="mt-2">
										<span class="fw-600 mr-2">Emergency Name : </span>
										<span class="fw-500">
											<span class="fw-500">
												<ShowValue
													:object="member"
													object-key="emergency_name"
													label="emergency name"
												></ShowValue>
											</span>
										</span>
									</div>
								</td>
								<td width="28.33%">
									<div class="mt-2">
										<span class="fw-600">SIC End Date : </span>
										<span class="fw-500">
											<ShowValue
												:object="member"
												object-key="sic_end_date_formatted"
												label="sic end date"
											></ShowValue>
										</span>
									</div>
								</td>
							</tr>
							<tr>
								<td width="28.33%">
									<div class="mt-2">
										<span class="fw-600"> Phone Number : </span>
										<span class="fw-500">
											<ShowValue :object="member" object-key="phone_number" label="phone number"></ShowValue>
										</span>
									</div>
								</td>
								<td width="28.33%">
									<div class="mt-2">
										<span class="fw-600">Emergency Relation : </span>
										<span class="fw-500">
											<ShowValue
												:object="member"
												object-key="emergency_contact_relation"
												label="emergency contact relation"
											></ShowValue>
										</span>
									</div>
								</td>
								<td width="28.33%">
									<div class="mt-2" v-if="member.is_resigned">
										<span class="fw-600">Resigned Date : </span>
										<span class="fw-500">
											<ShowValue :object="member" object-key="resigned_date" label="resigned date"></ShowValue>
										</span>
									</div>
								</td>
							</tr>
							<tr>
								<td width="28.33%">
									<div class="mt-2">
										<span class="fw-600"> Designation : </span>
										<span class="fw-500">
											<ShowValue :object="member" object-key="designation" label="designation"></ShowValue>
										</span>
									</div>
								</td>
								<td width="28.33%">
									<div class="mt-2">
										<span class="fw-600"> Emergency Phone No. : </span>
										<span class="fw-500">
											<ShowValue
												:object="member"
												object-key="emergency_phone_number"
												label="emergency phone number"
											></ShowValue>
										</span>
									</div>
								</td>
								<td width="28.33%">
									<div class="mt-2" v-if="member.is_resigned">
										<span class="fw-600">Resigned Reason : </span>
										<span class="fw-500">
											<ShowValue
												:object="member"
												object-key="resigned_reason"
												label="resigned reason"
											></ShowValue>
										</span>
									</div>
								</td>
							</tr>
							<tr>
								<td colspan="3">
									<div class="mt-2">
										<span class="fw-600">Remarks : </span>
										<span class="fw-500">
											<ShowValue :object="member" object-key="description" label="remarks"></ShowValue>
										</span>
									</div>
								</td>
							</tr>
						</table>
					</div>
				</v-flex>
			</v-layout>
			<div>
				<v-tabs
					v-model="memberTab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent"
					active-class="blue darken-4 text-white"
					hide-slider
				>
					<v-tab href="#event"> <v-icon small left>mdi-history</v-icon>Logs</v-tab>
					<v-tab href="#permission" v-if="false">
						<v-icon small left>mdi-history</v-icon>Permission</v-tab
					>
					<v-tab href="#password" v-if="!member.is_resigned">
						<v-icon small left>mdi-history</v-icon>Reset Password</v-tab
					>
				</v-tabs>

				<v-tabs-items v-model="memberTab">
					<v-tab-item value="event">
						<EventListing
							v-if="memberTab == 'event'"
							class="mx-4"
							type-text="Eng-Contractor"
							type="member"
							:type-uuid="uuid"
						></EventListing>
					</v-tab-item>
					<v-tab-item value="permission">
						<MemberPermission
							v-if="memberTab == 'permission'"
							v-model="db_permissions"
						></MemberPermission>
					</v-tab-item>
					<v-tab-item value="password" v-if="!member.is_resigned">
						<div class="mx-4">
							<div class="overflow-y" style="max-height: calc(100vh - 250px)">
								<v-layout class="p-4 border-bottom-light-grey min-height-57px">
									<v-flex class="font-level-3-bold my-auto">
										<span class="detail-svg-icon mr-2">
											<!--begin::Svg Icon-->
											<inline-svg :src="$assetURL('media/custom-svg/member-color.svg')" />
											<!--end::Svg Icon-->
										</span>
										Reset Password
									</v-flex>
									<v-flex class="font-level-3-bold my-auto float-right">
										<v-btn
											v-if="getPermission(`member::edit`)"
											:loading="pageLoading"
											class="white--text mr-2 float-right"
											depressed
											color="blue darken-4"
											tile
											:disabled="pageLoading"
											v-on:click="updatePassword()"
										>
											Reset Password
										</v-btn>
									</v-flex>
								</v-layout>
							</div>
						</div>
						<v-form
							ref="passwordForm"
							v-model.trim="formValid"
							lazy-validation
							v-on:submit.stop.prevent="updatePassword"
						>
							<v-row>
								<v-col md="6" class="py-0 pt-4" offset-md="3">
									<TextInput
										hide-details
										:disabled="pageLoading"
										:type="show1 ? 'text' : 'password'"
										:loading="pageLoading"
										:append-outer-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
										v-on:click:append-outer="show1 = !show1"
										id="first-name"
										placeholder="Password"
										:rules="[
											vrules.required(password, 'New Password'),
											vrules.minLength(password, 'New Password', 8),
											vrules.maxLength(password, 'New Password', 16),
										]"
										:class="{
											required: !password,
										}"
										v-model="password"
									></TextInput>
								</v-col>
								<v-col md="6" class="py-0" offset-md="3">
									<TextInput
										hide-details
										:disabled="pageLoading"
										:type="show3 ? 'text' : 'password'"
										:loading="pageLoading"
										:append-outer-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
										v-on:click:append-outer="show3 = !show3"
										id="first-name"
										placeholder="Confirm Password"
										:rules="[
											vrules.required(password_confirmation, 'Confirm Password'),
											vrules.confirmPassword(password_confirmation, 'Confirm Password', password),
											vrules.minLength(password_confirmation, 'Confirm Password', 8),
											vrules.maxLength(password_confirmation, 'Confirm Password', 16),
										]"
										:class="{
											required: !password_confirmation,
										}"
										v-model="password_confirmation"
									></TextInput>
								</v-col>
							</v-row>
						</v-form>
					</v-tab-item>
				</v-tabs-items>
			</div>
			<DeleteTemplate
				type="Member"
				:delete-text="deleteText"
				v-on:success="goBack()"
				v-on:close="deleteDialog = false"
				:delete-dialog="deleteDialog"
				:delete-url="deleteURL"
				:delete-note="
					member.activated
						? 'To preserve associated records, you could de-activate the Eng-Contractor instead.'
						: null
				"
			>
				<template v-if="member.activated" v-slot:extra-btn>
					<v-btn
						class="white--text"
						depressed
						color="blue darken-4"
						tile
						v-on:click="
							deleteDialog = false;
							deactivateDialog = true;
						"
					>
						De-activate
					</v-btn>
				</template>
			</DeleteTemplate>
			<DeactivateTemplate
				type="Member"
				:deactivate-text="deactivateText"
				v-on:success="goBack()"
				v-on:close="deactivateDialog = false"
				:deactivate-dialog="deactivateDialog"
				:deactivate-url="deactivateURL"
			>
			</DeactivateTemplate>
			<ActivateTemplate
				type="Member"
				:activate-text="activateText"
				v-on:success="goBack()"
				v-on:close="activateDialog = false"
				:activate-dialog="activateDialog"
				:activate-url="activateURL"
			>
			</ActivateTemplate>
			<ResignTemplate
				type="Member"
				:member-id="member.id"
				v-on:success="goBack()"
				v-on:close="resignDialog = false"
				:resign-dialog="resignDialog"
				:uuid="uuid"
			>
			</ResignTemplate>
		</template>
		<template v-else>
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>
	</v-sheet>
</template>
<style>
.w-15 {
	width: 125px;
}
</style>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GetMember, UpdateMemberPassword } from "@/core/lib/member.lib";
import ImageTemplate from "@/view/components/Image";
import ShowValue from "@/view/components/ShowValue";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import ActivateTemplate from "@/view/components/ActivateTemplate";
import ResignTemplate from "@/view/components/ResignTemplate";
import DeactivateTemplate from "@/view/components/DeactivateTemplate";
import EventListing from "@/view/components/EventListing";
import MemberPermission from "@/view/module/members/MemberPermission";
import TextInput from "@/view/components/TextInput";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";

export default {
	name: "member-detail",
	title: "Detail Member",
	data() {
		return {
			uuid: null,
			barcode: null,
			activateText: null,
			activateURL: null,
			deactivateText: null,
			deactivateURL: null,
			deleteText: null,
			deleteURL: null,
			password_confirmation: null,
			password: null,
			formValid: true,
			deleteDialog: false,
			addressDialog: false,
			resignDialog: false,
			deactivateDialog: false,
			activateDialog: false,
			contentLoaded: false,
			pageLoading: false,
			show1: false,
			show3: false,
			memberTab: "event",
			member: {},
			db_permissions: [],
		};
	},
	methods: {
		doAction(param) {
			switch (param) {
				case "edit":
					this.$router.push({
						name: "member-update",
						params: { uuid: this.uuid },
						query: { t: new Date().getTime() },
					});
					break;
				case "delete":
					this.deleteDialog = true;
					break;
				case "deactivate":
					this.deactivateDialog = true;
					break;
				case "activate":
					this.activateDialog = true;
					break;
				case "new-address":
					this.addressDialog = true;
					break;
				case "resign":
					this.resignDialog = true;
					break;
				case "print-label":
					break;
			}
		},
		getMember() {
			GetMember(this.uuid)
				.then((data) => {
					this.barcode = data.barcode;
					this.db_permissions = data.db_permissions;
					this.member = data;
					this.deleteText = `#${data.barcode} - ${data.display_name}`;
					this.deleteURL = `member/${data.uuid}`;
					this.activateText = `${data.name}`;
					this.activateURL = `member/${data.uuid}/activate`;
					this.deactivateText = `${data.display_name}`;
					this.deactivateURL = `member/${data.uuid}/de-activate`;
					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Eng-Contractor", disabled: true },
						{ text: "Detail", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					this.goBack();
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
		updatePassword() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.passwordForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.passwordForm.validate()) {
				return false;
			}

			UpdateMemberPassword(this.uuid, { password: this.password })
				.then(() => {
					this.password = null;
					this.password_confirmation = null;
					this.getMember();
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Password has been updated." },
					]);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
	},
	components: {
		ShowValue,
		EventListing,
		ImageTemplate,
		DeleteTemplate,
		ActivateTemplate,
		DeactivateTemplate,
		TextInput,
		ResignTemplate,
		MemberPermission,
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Eng-Contractor", disabled: true },
			{ text: "Detail", disabled: true },
		]);

		const { uuid } = this.$route.params;
		this.uuid = uuid;
		if (!this.uuid) {
			this.goBack();
		}
		this.getMember();
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
		addStatus() {
			if (this.member && this.member.is_resigned && this.member.is_resigned == 1) {
				return true;
			} else {
				return false;
			}
		},
	},
};
</script>
